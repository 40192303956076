export const initMegaMenu = () => {
    const menuBtn = document.querySelector('.headerMobile__menu__toggle');
    const mobileMegaMenu = document.querySelector('.mobileMegaMenu');

    if (!menuBtn && !mobileMegaMenu) return;

    const mobileMegaMenuCloseAll = document.querySelector('.mobileMegaMenu__headerInfoClose');
    const mobileMegaMenuChangeCategory = document.querySelector('.mobileMegaMenu__changeCategory');
    const mobileMegaMenuCategory = document.querySelector('.mobileMegaMenu__category');
    const mobileMegaMenuContent = document.querySelector('.mobileMegaMenu__content');
    const mobileMegaMenuCategoryBack = document.querySelector('.mobileMegaMenu__categoryBack');
    const mobileMegaMenuNav = document.querySelector('.mobileMegaMenu__nav');

    document.addEventListener("DOMContentLoaded", (event) => {
        menuBtn.addEventListener('click', function () {
            mobileMegaMenu.classList.add('active');
        });

        mobileMegaMenuCloseAll.addEventListener('click', function () {
            mobileMegaMenu.classList.remove('active');
            mobileMegaMenuCategory.classList.remove('show');
            mobileMegaMenuContent.classList.add('show');
            mobileMegaMenuNav.classList.remove('open');
            closeOpenMenus();
        });

        mobileMegaMenuChangeCategory.addEventListener('click', function () {
            mobileMegaMenuCategory.classList.add('show');
            mobileMegaMenuContent.classList.remove('show');
            mobileMegaMenuNav.classList.remove('open');
            closeOpenMenus();
        });

        mobileMegaMenuCategoryBack.addEventListener('click', function () {
            mobileMegaMenuCategory.classList.remove('show');
            mobileMegaMenuContent.classList.add('show');
        });
    });

    function closeOpenMenus() {
        const openAnchors = document.querySelectorAll('a.open');
        const openSubMenus = document.querySelectorAll('ul.sub-menu.open');

        openAnchors.forEach(anchor => {
            anchor.classList.remove('open');
        });

        openSubMenus.forEach(subMenu => {
            subMenu.classList.remove('open');
        });
    }
}


export const initMegaMenuTabs = () => {
    const elements = document.querySelectorAll('.mobileMegaMenu__content .menu li.menu-item-has-children');
    const mobileMegaMenuNav = document.querySelector('.mobileMegaMenu__nav');
    let mobileMegaMenuNavTitle = document.querySelector('.mobileMegaMenu__navTitle');
    const mobileMegaMenuNavBtn = document.querySelector('.mobileMegaMenu__navBtn');



    const arrowRight = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M4.30057 11.6707C4.14825 11.5184 4.06268 11.3117 4.06268 11.0963C4.06268 10.8809 4.14825 10.6742 4.30057 10.5219L8.32245 6.49999L4.30057 2.47812C4.15257 2.32488 4.07067 2.11964 4.07252 1.9066C4.07438 1.69357 4.15983 1.48978 4.31047 1.33914C4.46111 1.18849 4.6649 1.10305 4.87793 1.10119C5.09097 1.09934 5.29621 1.18124 5.44945 1.32924L10.0458 5.92555C10.1981 6.07792 10.2836 6.28455 10.2836 6.49999C10.2836 6.71544 10.1981 6.92206 10.0458 7.07443L5.44945 11.6707C5.29708 11.8231 5.09046 11.9086 4.87501 11.9086C4.65956 11.9086 4.45294 11.8231 4.30057 11.6707Z" fill="var(--primary)"/>
  </svg>`;

    if (elements.length > 0) {
        elements.forEach(element => {
            const item = element.querySelector('a');
            const spanArrow = document.createElement('span');
            spanArrow.setAttribute('class', 'arrow-right');
            spanArrow.innerHTML = arrowRight;
            item.appendChild(spanArrow);

            item.addEventListener("click", (e) => {
                e.preventDefault();
                if (item.classList.contains('open')) {
                    item.classList.remove('open');
                    element.querySelector('.sub-menu').classList.remove('open');
                } else {
                    item.classList.add('open');
                    element.querySelector('.sub-menu').classList.add('open');
                    mobileMegaMenuNav.classList.add('open');
                    const firstLinkText = element.querySelector('a').textContent;
                    mobileMegaMenuNavTitle.innerHTML = firstLinkText;
                }
            })
        })
    }

    function closeDeepestSubMenu() {
        const openSubMenus = document.querySelectorAll('.sub-menu.open');
        let lastOpenedSubMenu = null;

        openSubMenus.forEach(menu => {
            lastOpenedSubMenu = menu;
        });

        if (lastOpenedSubMenu) {
            const siblingAnchor = lastOpenedSubMenu.closest('li').querySelector('a');
            siblingAnchor.classList.remove('open');
            lastOpenedSubMenu.classList.remove('open');
        }
    }

    mobileMegaMenuNavBtn.addEventListener('click', function () {
        const mobileMegaMenuNavTitle = document.querySelector('.mobileMegaMenu__navTitle');
        const mobileMegaMenuNav = document.querySelector('.mobileMegaMenu__nav');

        closeDeepestSubMenu();

        const remainingOpenSubMenus = document.querySelectorAll('.sub-menu.open');
        if (remainingOpenSubMenus.length > 0) {
            const siblingAnchor = remainingOpenSubMenus[remainingOpenSubMenus.length - 1].closest('li').querySelector('a');
            siblingAnchor.classList.add('open');
            const text = siblingAnchor.textContent.trim();
            mobileMegaMenuNavTitle.textContent = text;
        } else {
            mobileMegaMenuNav.classList.remove('open');
        }
    });

}
