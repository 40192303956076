import Splide from "@splidejs/splide";
import { initBlogLoop, initCategoriesModal, initSearchForm } from "./modules/blog";
import { initCollapse } from "./modules/collapse";
import { initSingleFeaturedPosts, initSinglePostTableOfContents } from "./modules/single";
import { initIllnessSlider } from "../../includes/blocks/block-illness-slider/script";
import { initPostsSlider } from "../../includes/blocks/block-posts-slider/script";
import { initMegaMenu, initMegaMenuTabs } from "./modules/megaMenu";
import { initDesktopMenus, initMenuArrows } from "./modules/desktopMenu";
import { initExternalLinks } from "./modules/externalLinks";
import { initSearchHeader } from "./modules/search";
import HeroWithSearch from "../../includes/blocks/block-hero-with-search/script";
import {mobileImagesLightbox} from "./modules/mobileImagesLightbox";
import {moveToTop} from "./modules/moveToTop";
import {initMiniSearchBlock} from "../../includes/blocks/block-mini-search/script";

window.splide = Splide;
require('fslightbox');

initDesktopMenus();
moveToTop();
initMenuArrows();
initBlogLoop();
initSearchForm();
initCategoriesModal();
initSingleFeaturedPosts();
initSinglePostTableOfContents();
initIllnessSlider();
initPostsSlider();
initCollapse();
initMegaMenu();
initMegaMenuTabs();
initExternalLinks();
HeroWithSearch();
initSearchHeader();
mobileImagesLightbox();
initMiniSearchBlock();

/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if (document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener('wpcf7submit', function (event) {

    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false);

  formCF7.addEventListener('submit', function () {
    if (formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
