export const moveToTop = () => {
    const moveToTop = document.querySelector('.moveToTop');

    if(!moveToTop) return;

    document.addEventListener('scroll', () => {
        if(window.scrollY > 300) moveToTop.classList.add('active');
        else moveToTop.classList.remove('active');
    })

    moveToTop.addEventListener('click', () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
}