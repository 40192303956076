export const initDesktopMenus = () => {
    const header = document.querySelector('.headerDesktop');
    if (!header) return;
    initSideMenu();
}

const initSideMenu = () => {
    const sideMenu = document.querySelector('.headerDesktop .sideMenu');
    const toggle = document.querySelector('.headerDesktop .headerDesktop__sideNav__toggle');

    if (!sideMenu || !toggle) return;

    toggle.addEventListener('click', () => {
        sideMenu.classList.toggle('opened');
    });

    const closeBtn = sideMenu.querySelector('.sideMenu__title span.close');
    closeBtn.addEventListener('click', () => {
        sideMenu.classList.remove('opened');
    });
}

export const initMenuArrows = () => {
    const elements = document.querySelectorAll('.headerDesktop__mainNav .menu li.menu-item-has-children');


    const arrowRight = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M4.30057 11.6707C4.14825 11.5184 4.06268 11.3117 4.06268 11.0963C4.06268 10.8809 4.14825 10.6742 4.30057 10.5219L8.32245 6.49999L4.30057 2.47812C4.15257 2.32488 4.07067 2.11964 4.07252 1.9066C4.07438 1.69357 4.15983 1.48978 4.31047 1.33914C4.46111 1.18849 4.6649 1.10305 4.87793 1.10119C5.09097 1.09934 5.29621 1.18124 5.44945 1.32924L10.0458 5.92555C10.1981 6.07792 10.2836 6.28455 10.2836 6.49999C10.2836 6.71544 10.1981 6.92206 10.0458 7.07443L5.44945 11.6707C5.29708 11.8231 5.09046 11.9086 4.87501 11.9086C4.65956 11.9086 4.45294 11.8231 4.30057 11.6707Z" fill="#4A4A49"/>
  </svg>`;

    if (elements.length > 0) {
        elements.forEach(element => {
            const item = element.querySelector('a');
            const spanArrow = document.createElement('span');
            spanArrow.setAttribute('class', 'arrow-right');
            spanArrow.innerHTML = arrowRight;
            item.appendChild(spanArrow);
        })
    }


}
