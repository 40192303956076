export const mobileImagesLightbox = () => {
    if(window.innerWidth > 992) return;

    const images = document.querySelectorAll('.wp-block-image img');

    if(images.length === 0) return;

    const lightbox = new FsLightbox();

    images.forEach(image => {
       image.addEventListener('click', () => {
           lightbox.props.sources = [image.src];
           lightbox.open();
       });
    });
}