export const initMiniSearchBlock = () => {
    const $block = document.querySelector('.blockMiniSearch');
    if (!$block) return;

    const opener = $block.querySelector('.blockMiniSearch__opener');
    const closeBtn = $block.querySelector('.blockMiniSearch__close');
    const modal = $block.querySelector('.blockMiniSearch__wrapper > .blockMiniSearch__modal');
    opener.addEventListener('click', () => {
        $block.classList.add('opened');
        opener.classList.add('hide');
        modal.classList.add('active');
        closeBtn.classList.add('active');
    })

    closeBtn.addEventListener('click', () => {
        $block.classList.remove('opened');
        opener.classList.remove('hide');
        modal.classList.remove('active');
        closeBtn.classList.remove('active');
    });
}