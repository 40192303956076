const HeroWithSearch = () => {
    const blocks = document.querySelectorAll('.blockHeroWithSearch');

    if (!blocks) return;

    const endpoint = 'https://nominatim.openstreetmap.org/search';

    const submitForm = async (e) => {
        e.preventDefault();
        const form = e.target;

        const action = form.getAttribute('action');
        if (!action) {
            console.error('[Points Search] No action url found');
        }

        const data = new FormData(form);
        const illnessName = data.get('search_illness');
        const location = data.get('loc');
        const lng = data.get('lng');
        const lat = data.get('lat');

        const opener = form.querySelector('.blockHeroWithSearch__illness--opener');
        const input = form.querySelector('.blockHeroWithSearch__location input');

        opener.classList.remove('error');
        input.classList.remove('error');

        if (!illnessName) opener.classList.add('error');
        if (!location) input.classList.add('error');

        if(input.classList.contains('error') || opener.classList.contains('error')) return;

        try {
            if (!lng || !lat) {
                const response = await fetch(`${endpoint}?${new URLSearchParams({
                    format: 'json',
                    q: location
                }).toString()}`);
                if (!response.ok) return;
                const json = await response.json();

                if (!Array.isArray(json) || !json[0]) return;

                data.set('lng', json[0].lon);
                data.set('lat', json[0].lat);
                data.set('loc', json[0].display_name);
            }
        } catch (err) {
            console.error(err);
        } finally {

            data.delete('search_illness');
            const queryString = new URLSearchParams(data).toString();
            window.location = `${action}/${illnessName}/?${queryString.toString()}`;
        }
    }

    let controller;
    const findSuggestions = (e) => {
        const parentNode = e.target.parentNode;
        const suggestionsNode = parentNode.querySelector('.blockHeroWithSearch__suggestions');

        if (!suggestionsNode) return;
        suggestionsNode.innerHTML = "";

        const search = e.target.value;
        let timeout;

        clearTimeout(timeout);
        suggestionsNode.classList.remove('active');
        timeout = setTimeout(async () => {
            try {
                const response = await fetch(`${endpoint}?${new URLSearchParams({
                    format: 'json',
                    q: search
                }).toString()}`);
                if (timeout === controller) {
                    if (!response.ok) return;
                    response.json().then(data => {
                        if (!Array.isArray(data)) return;
                        data.forEach(item => {
                            const suggestionItem = document.createElement('span');
                            suggestionItem.innerHTML = item.display_name;
                            suggestionItem.addEventListener('click', () => {
                                const lng = parentNode.querySelector('input[name="lng"]');
                                const lat = parentNode.querySelector('input[name="lat"]');

                                lng.value = item.lon;
                                lat.value = item.lat;
                                e.target.value = item.display_name;
                                suggestionsNode.classList.remove('active');
                            });
                            suggestionsNode.appendChild(suggestionItem);
                        })
                        suggestionsNode.classList.add('active');
                    });
                }
            } catch (err) {
                console.error(err);
            }
        }, 50);
        controller = timeout;
    }

    document.addEventListener('click', e => {
        const suggestionBoxes = e.target.querySelectorAll('.blockHeroWithSearch__suggestions');
        if (!suggestionBoxes) return;
        suggestionBoxes.forEach(box => box.classList.remove('active'));
    });

    blocks.forEach(block => {
        const form = block.querySelector('.blockHeroWithSearch__form');
        const opener = block.querySelector('.blockHeroWithSearch__illness--opener');

        if (!form || !opener) return;

        opener.addEventListener('click', () => {
            form.classList.toggle('opened');
        })

        form.addEventListener('submit', submitForm);

        const illnesses = form.querySelector('.blockHeroWithSearch__list');
        if(illnesses){
            illnesses.addEventListener('change', () => {
                const checked = illnesses.querySelector('input[name=search_illness]:checked');
                if(!checked) return;
                opener.querySelector('span').innerHTML = checked.nextElementSibling.innerHTML;
                form.classList.remove('opened');
            });
        }

        const location = form.querySelector('input[name="loc"]');
        if (location) location.addEventListener('input', findSuggestions);
    })
}

export default HeroWithSearch;