
export const initSearchHeader = () => {
    let form = document.querySelector(".headerSearch");
    if(!form) return;

    let openToggle = document.querySelectorAll(".header_search");
    let closeToggle = form.querySelector(".headerSearch__close");

    if(!openToggle || !closeToggle) return;

    closeToggle.addEventListener('click', (event) => {
        form.classList.toggle("open");
    })

    openToggle.forEach((element) => {
        element.addEventListener('click', () => {
            form.classList.toggle("open");
        })
    })
}
