export const initIllnessSlider = () => {
    const illnessBlocks = document.querySelectorAll('.blockIllnessSlider');

    if(!illnessBlocks) return;

    illnessBlocks.forEach(illnessBlock => {
        const sliderElem = illnessBlock.querySelector('.blockIllnessSlider__slider');

        const slider = new window.splide(sliderElem, {
            type: 'slide',
            perPage: 4,
            perMove: 1,
            arrows: true,
            pagination: false,
            gap: 36,
            breakpoints: {
                992: {
                    perPage: 2,
                    arrows: false,
                    padding: {
                        right: 64
                    }
                },
                678: {
                    perPage: 1,
                    arrows: false,
                    padding: {
                        right: 64
                    }
                }
            }
        });

        slider.on( 'overflow', function ( isOverflow ) {
            if(window.innerWidth < 992) return;
            slider.options = {
                arrows    : isOverflow,
                drag      : isOverflow,
            };
        } );

        slider.mount();
    })
}