export const initExternalLinks = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const singlePostPage = document.querySelector('.pageWrapper');
    if(!singlePostPage) return;

    const links = singlePostPage.querySelectorAll('a');
    if(!links) return;

    links.forEach(link => {
      if(link.href.indexOf(location.hostname) === -1){
        link.target = '_blank';
      }
    });
  });
}