export const initCollapse = () => {
    const collapses = document.querySelectorAll('.collapse');

    if(!collapses) return;

    document.addEventListener("DOMContentLoaded", (event) => {
        collapses.forEach(collapse => {
            const question = collapse.querySelector('.collapse__question');
            const answerWrapper = collapse.querySelector('.collapse__wrapper');
            const answer = collapse.querySelector('.collapse__answer');

            question.addEventListener('click', () => {
                collapse.classList.toggle('active');
                const bgColor = hex2rgb(getComputedStyle(document.body).getPropertyValue('--primary'));
                if(collapse.classList.contains('active')){
                    answerWrapper.style.height = `${answer.clientHeight}px`;
                    if(!collapse.classList.contains('collapse--worker')) collapse.style.backgroundColor = `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, .1)`;
                } else {
                    answerWrapper.style.height = 0;
                    collapse.style.backgroundColor = getComputedStyle(document.body).getPropertyValue('--gray-10');
                }
            })
        })
    });
}

const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
}