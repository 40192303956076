export const initPostsSlider = () => {
    const postsSliderBlocks = document.querySelectorAll('.blockPostsSlider');

    if (!postsSliderBlocks) return;

    postsSliderBlocks.forEach(postsSliderBlock => {
        const sliderElem = postsSliderBlock.querySelector('.blockPostsSlider__slider');

        const slider = new window.splide(sliderElem, {
            type: 'loop',
            perPage: 3,
            perMove: 1,
            arrows: true,
            pagination: false,
            gap: 64,
            breakpoints: {
                992: {
                    perPage: 2
                },
                768: {
                    perPage: 2,
                    gap: 32,
                    arrows: false,
                    padding: {
                        right: 124
                    }
                },
                678: {
                    perPage: 1,
                },
                460: {
                    padding: {
                        right: 64
                    }
                }
            }
        });

        slider.on('overflow', function (isOverflow) {
            if (window.innerWidth < 768) return;
            slider.options = {
                arrows: isOverflow,
                drag: isOverflow,
            };
        });

        slider.mount();
    })
}