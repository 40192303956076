export const initBlogLoop = () => {
    const blogPage = document.querySelector('.blogPage');
    if(!blogPage) return;

    const loadMoreBtn = blogPage.querySelector('.blogPage__loadMore');

    if(!loadMoreBtn) return;

    const blogLoop = blogPage.querySelector('.blogPage__loop');
    loadMoreBtn.addEventListener('click', async () => {
        loadMoreBtn.classList.add('hidden');

        const response = await fetch(`/wp-json/flexible-theme/v1/posts`, {
            method: 'POST',
            body: JSON.stringify({
                page: loadMoreBtn.getAttribute('data-page'),
                ignore: loadMoreBtn.getAttribute('data-ignore'),
                perPage: loadMoreBtn.getAttribute('data-per-page'),
                illness: loadMoreBtn.getAttribute('data-illness'),
                search: loadMoreBtn.getAttribute('data-search'),
                cat: loadMoreBtn.getAttribute('data-cat')
            })
        });

        response.json().then(res => {
            if(res.html.length){
                const parser = new DOMParser();

                for(let i = 0; i < res.html.length; i++){
                    let element = parser.parseFromString(res.html[i], 'text/html').body.childNodes[0];

                    blogLoop.insertBefore(element, loadMoreBtn);
                    setTimeout(() => {
                        showSinglePostCard();
                    }, (i * 100));
                }

                setTimeout(() => {
                    showSinglePostCard();
                }, (res.html.length * 100));
            }

            loadMoreBtn.setAttribute('data-page', res.page)
            if(!res.pagination){
                loadMoreBtn.style.display = "none";
            }
        }).catch(err => {
            console.error(err);
        })
    })
}

export const initSearchForm = () => {
    const searchForm = document.querySelector('.searchForm');
    if(!searchForm) return;

    searchForm.addEventListener('submit', e => {
        const input = searchForm.querySelector(':scope > input');

        if(input.value.length === 0){
            e.preventDefault();
            console.log(searchForm.getAttribute('action'));
            window.location.href = searchForm.getAttribute('action');
        }
    });
}

export const initCategoriesModal = () => {
    const opener = document.querySelector('.blogPage__categories > span');
    const modal = document.querySelector('.blogPage__categories__modal');

    if(!opener || !modal) return;

    const wrapper = modal.querySelector('.blogPage__categories__modal__wrapper');

    modal.addEventListener('click', () => modal.close());

    wrapper.addEventListener('click', e => e.stopPropagation());

    opener.addEventListener('click', () => {
        modal.showModal();
    });

    const close = modal.querySelector('span.close');
    close.addEventListener('click', () => {
        modal.close();
    });
}

const showSinglePostCard = () => {
    const singlePostCard = document.querySelector('.blogPage__loop .hidden');
    if(!singlePostCard) return;

    singlePostCard.classList.remove('hidden');
}