export const initSingleFeaturedPosts = () => {
    const singleFeaturedPosts = document.querySelector('.postFeaturedPosts');

    if(!singleFeaturedPosts) return;

    const sliderWrapper = singleFeaturedPosts.querySelector('.postFeaturedPosts__loop');

    const slider = new window.splide(sliderWrapper, {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        pagination: false,
        gap: 24,
        arrows: false,
        padding: {
            right: 64,
        },
        mediaQuery: 'min',
        breakpoints: {
            1100: {
                destroy: true
            },
            768: {
                perPage: 2,
            },
            461: {
                padding: {
                    right: 128,
                },
            }
        }
    });

    slider.mount();
}

export const initSinglePostTableOfContents = () => {
    const postTableOfContents = document.querySelector('.postTableOfContents');

    if(!postTableOfContents) return;

    const tableContent = postTableOfContents.querySelector('.postTableOfContents__content');
    const header = document.querySelector('.headerDesktop');
    const content = document.querySelector('.singlePostPage article');

    if(content.innerHTML.length === 0) return;

    const headings = content.querySelectorAll('h2,h3');
    if(headings.length > 0){
        const base = document.createElement('ul');
        headings.forEach(heading => {
            let li = document.createElement('li');
            li.innerHTML = heading.outerText;
            li.addEventListener('click', () => {
                window.scroll({
                    top: heading.offsetTop - header.clientHeight - 16,
                    left: 0,
                    behavior: 'smooth'
                })
            });

            if(heading.localName === 'h3'){
                let ul = document.createElement('ul');
                ul.appendChild(li);
                base.appendChild(ul);
            } else{
                base.appendChild(li);
            }
        })
        tableContent.appendChild(base);
    } else {
        postTableOfContents.remove();
    }

    const tableTitle = postTableOfContents.querySelector('.postTableOfContents__title');
    tableTitle.addEventListener('click', () => {
        tableContent.classList.toggle('opened');
    });
}